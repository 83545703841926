import * as React from 'react';
import { useState, useEffect } from 'react';
import {
    GridToolbarContainer,
    // GridToolbarColumnsButton,
    // GridToolbarDensitySelector,
    GridToolbarFilterButton,
    GridToolbarExport,
} from '@mui/x-data-grid';
import { Button } from '@mui/material';
import UploadIcon from '@mui/icons-material/Upload';
import axios from "axios";
import { sleep } from '../components/sleep';
import { Typography } from '@mui/joy';

export default function CustomToolbar(props) {
    return (
        <div>
            <Typography align="center" level="h5" component="h1" sx={{ paddingTop: '1em', paddingBottom: '0.8rem' }}>
                Current CSV File
            </Typography>
            <Typography align="center" sx={{ paddingLeft: '1em', paddingRight: '1em' }}>
                The CSV file <b>must</b> contain the following columns: "name", and "number".
                Please note the below checkbox does <b>nothing</b>.
            </Typography>
            <GridToolbarContainer style={{ justifyContent: 'center' }}>
                {/* <GridToolbarColumnsButton /> */}
                {/* <GridToolbarDensitySelector /> */}
                <GridToolbarFilterButton />
                <UploadButton {...props} />
                <GridToolbarExport />
            </GridToolbarContainer>
        </div>
    );
}

const UploadButton = ({ refresh, setRefresh }) => {
    const [file, setFile] = useState();

    function onFileChange(event) {
        setFile(event.target.files[0])
        // console.log(event.target.files[0])
    }

    useEffect(() => {
        const url = '/uploadCSV';
        const formData = new FormData();
        formData.append('MYFILE', file);
        const config = {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            },
        };
        axios.post(url, formData, config).then((response) => {
            // console.log(response.data);
            sleep(1500).then(() => { setRefresh(refresh + 1) });
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [file]);


    return (
        <Button
            variant="text"
            component="label"
            startIcon={<UploadIcon />}
        >
            Upload New File
            <input
                type="file"
                hidden
                accept=".csv"
                onChange={onFileChange}
            />
        </Button>
    );
}

