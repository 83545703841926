import * as React from 'react';
import axios from "axios";

import Typography from '@mui/joy/Typography';
import TextField from '@mui/joy/TextField';
import Button from '@mui/joy/Button';
import Sheet from '@mui/joy/Sheet';
import { Alert } from '@mui/joy';


export default function Login({ setLoggedIn }) {

    React.useEffect(() => {
        axios(
            {
                method: 'get',
                url: '/loggedin',
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            }
        ).then((res) => {
            // console.log(res);
            if (res.data) {
                setLoggedIn(true);
            }
        }
        ).catch((err) => {
            console.log(err);
        }
        );
    }, [setLoggedIn]);

    const [username, setUsername] = React.useState('');
    const [password, setPassword] = React.useState('');
    const [loginError, setLoginError] = React.useState('none');
    const [usernameError, setUsernameError] = React.useState('none');

    const handleSubmit = (e) => {
        e.preventDefault();

        // Validate username: check if it contains only lowercase letters
        const usernamePattern = /^[a-z]+$/;
        if (!usernamePattern.test(username)) {
            setUsernameError();
            setUsername('');
            setPassword('');
            throw new Error('INVALID USERNAME');
        }

        axios(
            {
                method: 'post',
                url: '/login',
                data: {
                    username: username,
                    password: password
                }
            }
        ).then((res) => {
            // console.log(res);
            !res.data.token ? (() => { setLoginError(); setUsername(''); setPassword(''); throw new Error('LOGIN FAILED') })() : localStorage.setItem('token', res.data.token);
            setLoggedIn(true);
        }
        ).catch((err) => {
            console.log(err);
        }
        );
    }


    return (
        <Sheet
            sx={{
                width: 350,
                mx: 'auto', // margin left & right
                my: 4, // margin top & bottom
                py: 3, // padding top & bottom
                px: 2, // padding left & right
                display: 'flex',
                flexDirection: 'column',
                gap: 2,
                borderRadius: 'sm',
                boxShadow: 'md',
            }}
        >
            <Alert variant='soft' color='danger' style={{ display: loginError }}>
                Incorrect username or password
            </Alert>
            <Alert variant='soft' color='danger' style={{ display: usernameError }}>
                Username can only contain lower case letters
            </Alert>
            <Typography align="center" level="h4" component="h1">
                Welcome to <br />KCSOC Auto WhatsApp
            </Typography>

            <img src="/logo.jpg" alt="KCSOC Logo" style={{ width: "65%", padding: "1rem", margin: 'auto' }} />
            <Typography align="center" level="body2">Sign in to continue.</Typography>

            <form onSubmit={handleSubmit}>
                <TextField
                    name="username"
                    type="text"
                    placeholder="john.doe"
                    label="Username"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                    required
                    inputProps={{ style:"text-transform: lowercase" }}
                />

                <TextField
                    name="password"
                    type="password"
                    placeholder="********"
                    label="Password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    sx={{ mt: 1 }}
                    required
                />

                <Button 
                    sx={{ mt: 2 }} 
                    type="submit"
                >
                    Log in
                </Button>
            </form>
            {/* <Typography
                endDecorator={<Link href="/sign-up">Sign up</Link>}
                fontSize="sm"
                sx={{ alignSelf: 'center' }}
            >
                Don't have an account?
            </Typography> */}
        </Sheet>
    );
}
