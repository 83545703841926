// import axios from 'axios';
import React, { useState, useEffect } from 'react';
import QRCode from 'react-qr-code';
import io from 'socket.io-client';
import { Button, Textarea, Typography } from '@mui/joy';
// import { Snackbar } from '@mui/material';
// import MuiAlert from '@mui/material/Alert';
// import FadeModalDialog from '../components/FadeModalDialog';
import SendIcon from '@mui/icons-material/Send';
import DangerousIcon from '@mui/icons-material/Dangerous';
import LinearProgress from '@mui/joy/LinearProgress';

const socket = io({
    auth: {
        token: localStorage.getItem('token')
    }
});


function SendMessages() {

    const [isConnected, setIsConnected] = useState(socket.connected);
    // eslint-disable-next-line no-unused-vars
    const [lastPong, setLastPong] = useState(null);
    const [qrcode, setQrcode] = useState(null);
    const [fraction, setFraction] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [errorString, setErrorString] = useState("");

    useEffect(() => {
        socket.on('connect', () => {
            setIsConnected(true);
        });

        socket.on('disconnect', () => {
            setIsConnected(false);
        });

        socket.on('pong', () => {
            console.log()
            setLastPong(new Date().toISOString());
        });

        socket.on('qr', (qr) => {
            setQrcode(qr);
        });

        socket.on('fraction', (fraction) => {
            setFraction(fraction.fraction);
            if (fraction.fraction === 100) {
                setIsLoading(false);
            }
        });

        socket.on('error', (error) => {
            setErrorString(errorString + error + "\n");
        });

        return () => {
            socket.off('connect');
            socket.off('disconnect');
            socket.off('pong');
        };
    }, [errorString]);

    return (
        <div style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            width: '90%',
            maxWidth: 1000,
            margin: '1em auto',
            backgroundColor: 'var(--joy-palette-background-level1)',
            borderRadius: '10px',
            boxShadow: 'md',
        }}>
            <br />
            <Typography align="center" level="h5" component="h1">
                Sending Messages
            </Typography>

            <div style={{
                display: 'flex',
                flexWrap: 'wrap',
                justifyContent: 'center',
            }}>
                <div style={{
                    height: 20,
                    width: 20,
                    borderRadius: '50%',
                    backgroundColor: isConnected ? 'green' : 'red',
                    margin: '1em 0.5em',
                }} />
                <p style={{ margin: '1em 0.5em' }} >Connected to server: {'' + isConnected}</p>
            </div>

            {/* <p style={{ textAlign: 'center' }}>
                Please test the connection using the "Send Ping" button.
                <br />
                The QR code will update with a random test QR on each pong.
            </p>

            <Button variant="outlined" sx={{ margin: '1em auto' }} onClick={() => socket.emit('ping')}>Send ping (Last pong: {lastPong || '-'})</Button> */}

            <LinearProgress
                determinate
                variant="outlined"
                color="primary"
                size="sm"
                thickness={32}
                value={fraction}
                sx={{
                    '--LinearProgress-radius': '0px',
                    '--LinearProgress-progressThickness': '24px',
                    boxShadow: 'sm',
                    borderColor: 'neutral.500',
                    width: '80%',
                }}
            >
                <Typography
                    // level="body3"
                    fontWeight="xl"
                    textColor="common.white"
                    sx={{ mixBlendMode: 'difference' }}
                >
                    Sending Messages... {`${Math.round(fraction)}%`}
                </Typography>
            </LinearProgress>

            <div style={{
                display: 'flex',
                flexWrap: 'wrap',
                justifyContent: 'center',
            }}>
                <Button
                    sx={{ margin: '1em', width: '250px' }}
                    endDecorator={<SendIcon />}
                    variant="solid"
                    color="primary"
                    onClick={() => {
                        socket.emit("startWA");
                        setIsLoading(true);
                    }}
                    loadingPosition="end"
                    loading={isLoading}
                >
                    Start Sending Messages
                </Button>

                {/* Emergency Stop button */}
                <Button
                    sx={{ margin: '1em', width: '250px' }}
                    endDecorator={<DangerousIcon />}
                    variant="solid"
                    color="danger"
                    onClick={() => {
                        socket.emit("emergencyStop")
                        setIsLoading(false);
                    }}>
                    Emergency Stop
                </Button>
            </div>
            
            <Textarea
                sx={{
                    margin: '1em',
                    width: '80%',
                    maxWidth: '600px',
                    color: 'var(--joy-palette-text-level1) !important',
                }}
                placeholder="Any server errors will show up here"
                value={errorString}
                readOnly={true}
                minRows={5}
                maxRows={5}
            />

            <Typography align="center" level="h5" component="h1">
                WhatsApp Web QR Code:
            </Typography>
            <p style={{ textAlign: 'center', maxWidth: '500px' }}>
                Once you press the start button below, the QR code will be replaced with a QR code for the current session.
            </p>
            {
                qrcode ?
                    <QRCode size={150} value={qrcode} style={{ margin: '1em', height: '150px', width: '150px' }} />
                    :
                    <div style={{
                        margin: '1em',
                        width: '150px',
                        height: '150px',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        border: '1px solid var(--joy-palette-background-level2)',
                    }}>
                        <p>No QR Code</p>
                    </div>
            }
            <p style={{ textAlign: 'center', maxWidth: '500px' }}>
                Please scan the QR code using WhatsApp Web on your phone.
            </p>
            <p style={{ textAlign: 'center', maxWidth: '500px' }}>
                Please note this session is saved to the server under your account so you may not be required to scan the QR code every time.
                <br />
                DO NOT share your account with anyone else.
            </p>

        </div >
    );
}

export default SendMessages;
