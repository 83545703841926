import * as React from 'react';
import Button from '@mui/joy/Button';
import TextField from '@mui/joy/TextField';
import Modal from '@mui/joy/Modal';
import ModalDialog from '@mui/joy/ModalDialog';
import Stack from '@mui/joy/Stack';
// import Add from '@mui/icons-material/Add';
import Typography from '@mui/joy/Typography';
import Alert from '@mui/joy/Alert';

export default function RegisterModal({ open, setOpen }) {
  const [username, setUsername] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [repeatPassword, setRepeatPassword] = React.useState('');
  const [secretKey, setSecretKey] = React.useState('');
  const [success, setSuccess] = React.useState('none');
  const [error, setError] = React.useState('none');
  const [usernameError, setUsernameError] = React.useState('none');

  return (
    <React.Fragment>
      <Modal open={open} onClose={() => {
        setOpen(false);
        resetAll();
      }}>
        <ModalDialog
          aria-labelledby="basic-modal-dialog-title"
          aria-describedby="basic-modal-dialog-description"
          sx={{
            maxWidth: 500,
            borderRadius: 'md',
            p: 3,
            boxShadow: 'lg',
          }}
        >
          <Typography
            id="basic-modal-dialog-title"
            component="h2"
            level="inherit"
            fontSize="1.25em"
            mb="0.25em"
          >
            Register for an account
          </Typography>
          <Typography
            id="basic-modal-dialog-description"
            mt={0.5}
            mb={2}
            textColor="text.tertiary"
          >
            Fill in the information to create your account.
          </Typography>
          <form
            onSubmit={async (event) => {
              event.preventDefault();

              // Validate username: check if it contains only lowercase letters
              const usernamePattern = /^[a-z]+$/;
              if (!usernamePattern.test(username)) {
                resetAll();
                setUsernameError();
                return;
              }
              // check if passwords match
              if (password !== repeatPassword) {
                resetAll();
                setError('block');
                return;
              }
              // send request to server
              const response = await fetch('/register', {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                  username: username,
                  password: password,
                  secretKey: secretKey,
                }),
              });
              if (response.status === 200) {
                resetAll();
                setSuccess('block');
                setError('none');
                setUsernameError('none')
              } else {
                resetAll();
                setError('block');
              }
            }}
          >
            <Stack spacing={2}>
              <Alert variant='soft' color='success' style={{ display: success }}>
                Successfully registered!
                <br />
                Please click away and log in.
              </Alert>
              <Alert variant='soft' color='danger' style={{ display: error }}>
                There was an error! Please try again later.
              </Alert>
              <Alert variant='soft' color='danger' style={{ display: usernameError }}>
                Username can only contain lower case letters
              </Alert>
              <TextField
                label="Username" autoFocus required value={username}
                onChange={(e) => setUsername(e.target.value)}
              />
              <TextField
                type="password" label="Password" required value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              <TextField
                type="password" label="Repeat Password" required value={repeatPassword}
                onChange={(e) => setRepeatPassword(e.target.value)}
              />
              <TextField
                label="Secret Key (ask Project Owner)" required value={secretKey}
                onChange={(e) => setSecretKey(e.target.value)}
              />
              <Button type="submit">Submit</Button>
            </Stack>
          </form>
        </ModalDialog>
      </Modal>
    </React.Fragment>
  );

  function resetAll() {
    setUsername('');
    setPassword('');
    setRepeatPassword('');
    setSecretKey('');
    setSuccess('none');
    setError('none');
    setUsernameError('none')
  }
}
