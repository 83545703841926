import * as React from 'react';
import { useState, useEffect } from 'react';
import axios from "axios";

import Typography from '@mui/joy/Typography';
import Sheet from '@mui/joy/Sheet';
import { Textarea } from '@mui/joy';
import { DataGrid } from '@mui/x-data-grid';
import CustomToolbar from '../components/CustomToolbar';
import SendMessages from './SendMessages';
import GroupDownloader from './GroupDownloader';
import UploadPicture from '../components/UploadPicture';
import DeletePicture from '../components/DeletePicture';
import ImageNotSupportedIcon from '@mui/icons-material/ImageNotSupported';
// import FadeModalDialog from '../components/FadeModalDialog';

const columns = [
    { field: 'id', headerName: '', width: 70, headerAlign: 'center', align: 'center' },
    { field: 'name', headerName: 'name', width: 200, headerAlign: 'center', align: 'center' },
    { field: 'number', headerName: 'number', width: 200, headerAlign: 'center', align: 'center' },
];
export default function MainPage({ setLoggedIn }) {

    const [refresh, setRefresh] = useState(0);

    useEffect(() => {
        axios(
            {
                method: 'get',
                url: '/loggedin',
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            }
        ).then((res) => {
            // console.log(res);
            if (res.data) {
                setLoggedIn(true);
            }
        }
        ).catch((err) => {
            console.log(err);
        }
        );
    }, [setLoggedIn]);

    const [message, setMessage] = useState('');

    useEffect(
        () => {
            // Wait 1000ms before uploading the message
            const timeout = setTimeout(() => {
                axios(
                    {
                        method: 'post',
                        url: '/uploadMessage',
                        data: {
                            message: message,
                        },
                        headers: {
                            'Authorization': `Bearer ${localStorage.getItem('token')}`
                        }
                    }
                ).then((res) => {
                    // console.log(res);
                    console.log("Message uploaded")
                }
                ).catch((err) => {
                    console.log(err);
                }
                );
            }, 100);

            // If the hook is called again, cancel the previous timeout
            // This creates a debounce instead of a delay
            return () => clearTimeout(timeout);
        },
        // Run the hook every time the user makes a keystroke
        [message]
    )

    // download contacts from server
    const [contacts, setContacts] = useState([]);
    useEffect(() => {
        axios(
            {
                method: 'get',
                url: '/contacts',
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            }
        ).then((res) => {
            // console.log(res.data.contacts);
            setContacts(res.data.contacts);
        }
        ).catch((err) => {
            console.log(err);
        }
        );
    }, [refresh]);

    const [pic, setPic] = useState(null);
    useEffect(() => {
        axios(
            {
                method: 'get',
                url: '/pic',
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            }
        ).then((res) => {
            // console.log(res.data.pic);
            res.status === 201 ? setPic(null) : setPic(res.data.pic);
        }
        ).catch((err) => {
            console.log(err);
        }
        );
    }, [refresh]);

    useEffect(() => {
        axios(
            {
                method: 'get',
                url: '/message',
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            }
        ).then((res) => {
            setMessage(res.data.message);
        }
        ).catch((err) => {
            console.log(err);
        }
        );
    }, [refresh]);

    return (
        <div>

            <Sheet sx={{
                p: 2,
                display: "flex",
                flexDirection: "column",
            }}>
                <Typography align="center" level="h6" component="h2">
                    Notice: Please do not try to send more than 250 messages at a time. It may result in a ban from WhatsApp.
                </Typography>
                <div style={{
                    display: 'flex', flexWrap: 'wrap'
                }}>
                    <div
                        style={{
                            height: 500,
                            width: '90%',
                            maxWidth: 600,
                            margin: '1em auto',
                            backgroundColor: 'var(--joy-palette-background-level1)',
                            borderRadius: '10px',
                            boxShadow: 'md',
                        }}
                    >
                        <DataGrid
                            rows={
                                contacts.map((contact, index) => {
                                    return { id: index, name: contact.name, number: contact.number }
                                })
                            }
                            columns={columns}
                            components={{ Toolbar: CustomToolbar }}
                            componentsProps={{ toolbar: { setRefresh, refresh } }}
                            checkboxSelection
                            density="compact"
                            sx={{
                                '& .MuiDataGrid-main': {
                                    backgroundColor: 'var(--joy-palette-background-surface)',
                                    width: '95%',
                                    margin: '0 auto',
                                    borderRadius: '10px',
                                },
                            }}
                        />
                    </div>
                    <div
                        style={{
                            height: 500,
                            width: '90%',
                            maxWidth: 600,
                            margin: '1em auto',
                            backgroundColor: 'var(--joy-palette-background-level1)',
                            borderRadius: '10px',
                            boxShadow: 'md',
                        }}
                    >

                        <Typography align="center" level="h5" component="h1" sx={{ padding: '1em', paddingBottom: '0.8rem' }}>
                            Current Message
                        </Typography>
                        <Typography align="center" sx={{ paddingLeft: '1em', paddingRight: '1em', paddingBottom: '0.8rem' }}>
                            You may use the <b>{"{NAME}"}</b> merge tag, which will be replaced with the contact's name.
                        </Typography>

                        <Textarea
                            placeholder="Enter your message here"
                            value={message}
                            minRows={14}
                            maxRows={14}
                            onChange={({ target }) => setMessage(target.value)}
                            sx={{
                                width: '95%',
                                margin: 'auto',
                            }}
                        />

                    </div>

                    <div
                        style={{
                            height: 500,
                            width: '90%',
                            maxWidth: 600,
                            margin: '1em auto',
                            backgroundColor: 'var(--joy-palette-background-level1)',
                            borderRadius: '10px',
                            boxShadow: 'md',
                        }}
                    >

                        <Typography align="center" level="h5" component="h1" sx={{ padding: '1em', paddingBottom: '0.8rem' }}>
                            Current Image
                        </Typography>
                        <Typography align="center" sx={{ paddingLeft: '1em', paddingRight: '1em', paddingBottom: '0.8rem' }}>
                            To send an image with each message, please upload an image here. If there is <b>no image, the messages will be sent without an image</b>.
                        </Typography>
                        <Typography align="center" sx={{ paddingLeft: '1em', paddingRight: '1em', paddingBottom: '0.8rem' }}>
                            <UploadPicture setRefresh={setRefresh} refresh={refresh} />
                            <DeletePicture setRefresh={setRefresh} refresh={refresh} />
                        </Typography>

                        <div style={{
                            width: '90%',
                            height: '60%',
                            margin: 'auto',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}>
                            {pic === null ?
                                <ImageNotSupportedIcon sx={{ fontSize: 50 }} />
                                :
                                <img alt="" src={`data:image/jpeg;base64,${pic}`} style={{
                                    margin: 'auto',
                                    maxWidth: '100%',
                                    maxHeight: '100%',
                                }} />
                            }
                        </div>

                    </div>

                </div>
                {/* <FadeModalDialog /> */}
                <SendMessages />
                {/* <GroupDownloader /> */}
            </Sheet>
        </div>
    );
}
