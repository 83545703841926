import * as React from 'react';
import { Button } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import axios from "axios";
import { sleep } from './sleep';

export default function DeletePicture({ refresh, setRefresh }) {

    return (
        <Button
            variant="text"
            component="label"
            startIcon={<DeleteIcon />}
            onClick={() => {

                const url = '/deletePic';
                const config = {
                    headers: {
                        'Authorization': `Bearer ${localStorage.getItem('token')}`
                    },
                };
                axios.get(url, config).then((response) => {
                    // console.log(response.data);
                    sleep(1500).then(() => { setRefresh(refresh + 1); });
                }
                );
            }}
        >
            Delete Uploaded Picture
        </Button>
    );
}

