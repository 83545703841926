import * as React from 'react';
import { useState, useEffect } from 'react';
import { Button } from '@mui/material';
import UploadIcon from '@mui/icons-material/Upload';
import axios from "axios";
import { sleep } from '../components/sleep';

export default function UploadPicture({ refresh, setRefresh }) {
    const [file, setFile] = useState();

    function onFileChange(event) {
        setFile(event.target.files[0]);
        // console.log(event.target.files[0])
    }

    useEffect(() => {
    const url = '/uploadPic';
        const formData = new FormData();
        formData.append('MYFILE', file);
        const config = {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            },
        };
        axios.post(url, formData, config).then((response) => {
            // console.log(response.data);
            sleep(1500).then(() => { setRefresh(refresh + 1); });
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [file]);


    return (
        <Button
            variant="text"
            component="label"
            startIcon={<UploadIcon />}
        >
            Upload New Picture
            <input
                type="file"
                hidden
                accept=".jpg, .jpeg, .png"
                onChange={onFileChange} />
        </Button>
    );
}

