import React from "react";
// import TestPage from "./TestPage";
import MainPage from "./MainPage";
import Login from "./Login";

import CssBaseline from '@mui/joy/CssBaseline';
import { ColorSchemeToggle } from '../components/ColorSchemeToggle';
import Box from '@mui/joy/Box';
import Typography from '@mui/joy/Typography';
import IconButton from '@mui/joy/IconButton';
// import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
// import BookRoundedIcon from '@mui/icons-material/BookRounded';
import Layout from '../components/Layout';
// import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import LogoutIcon from '@mui/icons-material/Logout';
import PersonAddIcon from '@mui/icons-material/PersonAdd';

import { deepmerge } from '@mui/utils';
import { experimental_extendTheme as extendMuiTheme } from '@mui/material/styles';
import colors from '@mui/joy/colors';
import {
    extendTheme as extendJoyTheme,
    CssVarsProvider,
    // useColorScheme,
} from '@mui/joy/styles';
import packageJson from '../../package.json';
import RegisterModal from "../components/RegisterModal";

const muiTheme = extendMuiTheme({
    // This is required to point to `var(--joy-*)` because we are using
    // `CssVarsProvider` from Joy UI.
    cssVarPrefix: 'joy',
    colorSchemes: {
        light: {
            palette: {
                primary: {
                    main: colors.blue[500],
                },
                grey: colors.grey,
                error: {
                    main: colors.red[500],
                },
                info: {
                    main: colors.purple[500],
                },
                success: {
                    main: colors.green[500],
                },
                warning: {
                    main: colors.yellow[200],
                },
                common: {
                    white: '#FFF',
                    black: '#09090D',
                },
                divider: colors.grey[200],
                text: {
                    primary: colors.grey[800],
                    secondary: colors.grey[600],
                },
            },
        },
        dark: {
            palette: {
                primary: {
                    main: colors.blue[400],
                },
                grey: colors.grey,
                error: {
                    main: colors.red[600],
                },
                info: {
                    main: colors.purple[600],
                },
                success: {
                    main: colors.green[600],
                },
                warning: {
                    main: colors.yellow[300],
                },
                common: {
                    white: '#FFF',
                    black: '#09090D',
                },
                divider: colors.grey[800],
                text: {
                    primary: colors.grey[100],
                    secondary: colors.grey[300],
                },
            },
        },
    },
});

const joyTheme = extendJoyTheme();

// You can use your own `deepmerge` function.
// joyTheme will deeply merge to muiTheme.
const theme = deepmerge(muiTheme, joyTheme);


export default function App() {
    const [loggedIn, setLoggedIn] = React.useState(false);
    const [open, setOpen] = React.useState(false);

    // const [drawerOpen, setDrawerOpen] = React.useState(false);

    return (
        <CssVarsProvider theme={theme}>
            <CssBaseline />
            {/* <Layout.Root
                sx={{
                    ...(drawerOpen && {
                        height: '100vh',
                        overflow: 'hidden',
                    }),
                }}
            > */}
            <Layout.Header>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        gap: 1.5,
                    }}
                >
                    <IconButton
                        size="sm"
                        variant="solid"
                    // sx={{ display: { xs: 'none', sm: 'inline-flex' } }}
                    >
                        {/* <WhatsAppIcon /> */}
                        <img src="/android-chrome-192x192.png" alt="logo" width="30" height="30" />
                    </IconButton>
                    <Typography component="h1" fontWeight="xl">
                        Auto WhatsApp v{packageJson.version}
                    </Typography>
                </Box>
                <Box sx={{ display: 'flex', flexDirection: 'row', gap: 1.5 }}>
                    {/* <IconButton
                        size="sm"
                        variant="outlined"
                        color="primary"
                        sx={{ display: { xs: 'inline-flex', sm: 'inline-flex' } }}
                    >
                        <SearchRoundedIcon />
                    </IconButton> */}
                    {
                        !loggedIn ?
                            <IconButton
                                size="sm"
                                variant="outlined"
                                color="primary"
                                component="a"
                                onClick={() => setOpen(true)}
                            >
                                <PersonAddIcon />
                            </IconButton>
                            :
                            <div />
                    }
                    <IconButton
                        size="sm"
                        variant="outlined"
                        color="primary"
                        component="a"
                        onClick={() => {
                            localStorage.removeItem('token')
                            setLoggedIn(false)
                        }}
                    >
                        <LogoutIcon />
                    </IconButton>
                    <ColorSchemeToggle />
                </Box>
            </Layout.Header>
            {/* <Layout.Main> */}
            <RegisterModal open={open} setOpen={setOpen} />
            {
                !loggedIn ?
                    <Login setLoggedIn={setLoggedIn} />
                    :
                    <MainPage setLoggedIn={setLoggedIn} />
            }
            {/* </Layout.Main> */}
            {/* </Layout.Root > */}
        </CssVarsProvider>
    );
}
